//Fonts
$font-main: "proximanova",Arial, Helvetica, sans-serif;
$font-main-bold: "proximanova-bold",Arial, Helvetica, sans-serif;
$font-main-light: "proximanova-light",Arial, Helvetica, sans-serif;
$font-headline: 'requiem', Georgia, Times, "Times New Roman", serif;
$font-headline-bold: 'requiem', Georgia, Times, "Times New Roman", serif;
$font-headline-light: 'requiem', Georgia, Times, "Times New Roman", serif;

$color-black: #000000;
$color-white: #FFFFFF;

$color-primary: #000000; /* main dominant color from logo */
$color-secondary: #000000; /* compliments main color */
$color-tertiary: #ffffff; /* unique color, most likely from logo */
$color-surrogate: #ffffff; /* a fourth color if necesary */
$color-secondary-light: #ffffff;

// BREAKPOINTS
$screen-xs: 350px; // Your phone is too small...
$screen-sm: 640px; // iPhone
$screen-md: 768px; // iPad
$mq-md-lg: 845px;
$screen-lg: 1024px; // Laptop
$screen-xlg: 1248px; // Desktop
$mq-xxlg: 1500px;
