@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

// iOS issue where native components in forms scale view port fix
@mixin nativeiOScomponentScale() {
  input[type="text"], input[type="text"]:focus, select:focus, textarea:focus, input:focus {
    font-size: 16px !important;
  }
}

// Alignments
@mixin vertical-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-align {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-align {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center-vert {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
}

@mixin center-horz {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
}

@mixin centerall {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
}

@mixin svg-shadow($args) {
	-webkit-filter: drop-shadow(#{$args});
	-moz-filter: drop-shadow(#{$args});
	-o-filter: drop-shadow(#{$args});
	filter: drop-shadow(#{$args});
}

@mixin centernone {
	position: relative !important;
	top: auto !important;
	left: auto !important;
	transform: translate(0%, 0%) !important;
	-webkit-transform: translate(0%, 0%) !important;
	-moz-transform: translate(0%, 0%) !important;
	-ms-transform: translate(0%, 0%) !important;
	-o-transform: translate(0%, 0%) !important;
}

// General Transitions
@mixin transition($args) {
  -webkit-transition: #{$args};
  -moz-transition: #{$args};
  -ms-transition: #{$args};
  -o-transition: #{$args};
  transition: #{$args};
}

// General Box Shadow
@mixin box-shadow($args) {
  -webkit-box-shadow: #{$args};
  -moz-box-shadow: #{$args};
  -ms-box-shadow: #{$args};
  -o-box-shadow: #{$args};
  box-shadow: #{$args};
}

@mixin appearance($args) {
  appearance: $args;
  -webkit-appearance: $args;
  -moz-appearance: $args;
}

//Keyframe Animations
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

// Responsive
@mixin mq-xs {
  @media screen and (max-width: #{$screen-xs}) {
    @content;
  }
}

@mixin mq-sm {
  @media screen and (max-width: #{$screen-sm}) {
    @content;
  }
}

@mixin mq-md {
  @media screen and (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin mq-lg {
  @media screen and (max-width: #{$screen-lg}) {
    @content;
  }
}

@mixin mq-xlg {
  @media screen and (max-width: #{$screen-xlg}) {
    @content;
  }
}


@mixin mq-min($args) {
  @media (min-width: (#{$args})) {
    @content;
  }
}

@mixin mq-max($args) {
  @media (max-width: (#{$args})) {
    @content;
  }
}

// Transparent Colors
@function fn-transparent-color($color , $opacity) {
  @return rgba(red($color), green($color), blue($color), $opacity);
} // fn-transparent-color($color-white , .85)

// Gradients Backgrounds
@mixin bg-gradient($start-color, $end-color, $start-postion, $end-position, $start-opacity, $end-opacity) {
    background: $start-color;
    background: -moz-linear-gradient($start-postion,  $start-color $start-opacity, $end-color $end-opacity);
    background: -webkit-linear-gradient($start-postion,  $start-color $start-opacity, $end-color $end-opacity);
    background: linear-gradient(to $end-position,  $start-color $start-opacity,$end-color $end-opacity);
}

// Rotate
@mixin rotate($deg) {
  -ms-transform: rotate($deg); /* IE 9 */
  -webkit-transform: rotate($deg); /* Chrome, Safari, Opera */
  transform: rotate($deg);
}
