/*
       _ _                     _   _
 _ __ / / | ___ _ __ ___  __ _| |_(_)_   _____
| '_ \| | |/ __| '__/ _ \/ _` | __| \ \ / / _ \
| |_) | | | (__| | |  __/ (_| | |_| |\ V /  __/
| .__/|_|_|\___|_|  \___|\__,_|\__|_| \_/ \___|
|_|

Site design by p11|creative
Visit us @ p11.com

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  JARED GRID + MEYER RESET
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}

:focus {
  outline: 0;
}

body {
  line-height: 1;
  color: #000;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  font-family: Georgia, "Times New Roman", Times, serif;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
}

blockquote, q {
  quotes: "" "";
}

*, *:before, *:after {
    border:0;
    border-collapse:collapse;
    margin:0;
    padding:0;
    /*line-height:normal;*/
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -ms-box-sizing:border-box;
    -o-box-sizing:border-box;
    box-sizing:border-box;
}

body {
  font: 400 normal 16px/16px Arial, Helvetica, sans-serif;
}

audio,
canvas,
img,
video {
    vertical-align: middle;
}

section {
  position:relative;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}


input, textarea {
  font:inherit;
}

input[type="submit"] {
  box-shadow: none;
}

textarea {
    resize: vertical;
    font-family: inherit;
    font-size: inherit;
}

a, .trans {
  -webkit-transition:all .5s;
  -moz-transition:all .5s;
  -ms-transition:all .5s;
  -o-transition:all .5s;
  transition:all .5s;
}

.full {
    width:100%;
}

.half {
    width:50%;
}

.third {
    width:33.33%;
}

.twothird {
    width:66.66%;
}

.fourth {
    width:25%;
}

.threefourth {
    width:75%;
}

.left {
    float:left;
}

.right {
    float:right;
}

.center {
    margin:0 auto;
}

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.fill {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 200;
}

/*~~ TEXT ALIGN ~~*/

.tleft {
  text-align: left;
}

.tright {
  text-align: right;
}

.tcenter {
  text-align: center;
}

/*~~ PADDING ~~*/

.no-pad,
.nopad,
.pad0 {
	padding: 0;
}

.pad-half {
	padding: 0.5em;
}

.pad1-0 {
	padding: 1em 0;
}

.pad2-0 {
	padding: 2em 0;
}

.pad3-0 {
	padding: 3em 0;
}

.pad4-0 {
	padding: 4em 0;
}

.pad0-1 {
	padding: 0 1em;
}

.pad0-2 {
	padding: 0 2em;
}

.pad0-3 {
	padding: 0 3em;
}

.pad0-4 {
	padding: 0 4em;
}

.pad1-2 {
	padding: 1em 2em;
}

.pad1-3 {
	padding: 1em 3em;
}

.pad1-4 {
	padding: 1em 4em;
}

.pad2-1 {
	padding: 2em 1em;
}

.pad2-3 {
	padding: 2em 3em;
}

.pad2-4 {
	padding: 2em 4em;
}

.pad3-1 {
	padding: 3em 1em;
}

.pad3-2 {
	padding: 3em 2em;
}

.pad3-4 {
	padding: 3em 4em;
}

.pad4-1 {
	padding: 4em 1em;
}

.pad4-2 {
	padding: 4em 2em;
}

.pad4-3 {
	padding: 4em 3em;
}

.pad1 {
	padding: 1em;
}

.pad2 {
	padding: 2em;
}

.pad3 {
	padding: 3em;
}

.pad4 {
	padding: 4em;
}

/*~~ PADDING  TOP~~*/

.padt1 {
	padding-top: 1em;
}

.padt2 {
	padding-top: 2em;
}

.padt3 {
	padding-top: 3em;
}

.padt4 {
	padding-top: 4em;
}

.padl1 {
	padding-left: 1em;
	@include mq-sm {
		padding-left: 0;
	}
}

/*~~ PADDING BOTTOM ~~*/

.padb1 {
	padding-bottom: 1em;
}

.padb2 {
	padding-bottom: 2em;
}

.padb3 {
	padding-bottom: 3em;
}

.padb4 {
	padding-bottom: 4em;
}

/*~~ MARGIN ~~*/

.no-mar,
.nomar,
.nomarg,
.mar0 {
	margin: 0;
}

.mar-half {
	margin: 0.5em;
}

.mar1-0 {
	margin: 1em 0;
}

.mar2-0 {
	margin: 2em 0;
}

.mar3-0 {
	margin: 2em 0;
}

.mar4-0 {
	margin: 4em 0;
}

.mar0-1 {
	margin: 0 1em;
}

.mar0-2 {
	margin: 0 2em;
}

.mar0-3 {
	margin: 0 3em;
}

.mar0-4 {
	margin: 0 4em;
}

.mar1-2 {
	margin: 1em 2em;
}

.mar1-3 {
	margin: 1em 3em;
}

.mar1-4 {
	margin: 1em 4em;
}

.mar2-1 {
	margin: 2em 1em;
}

.mar2-3 {
	margin: 2em 3em;
}

.mar2-4 {
	margin: 2em 4em;
}

.mar3-1 {
	margin: 3em 1em;
}

.mar3-2 {
	margin: 3em 2em;
}

.mar3-4 {
	margin: 3em 4em;
}

.mar4-1 {
	margin: 4em 1em;
}

.mar4-2 {
	margin: 4em 2em;
}

.mar4-3 {
	margin: 4em 3em;
}

.mar1 {
	margin: 1em;
}

.mar2 {
	margin: 2em;
}

.mar3 {
	margin: 3em;
}

.mar4 {
	margin: 4em;
}

/*~~ margin  TOP~~*/

.mart1 {
	margin-top: 1em;
}

.mart2 {
	margin-top: 2em;
}

.mart3 {
	margin-top: 3em;
}

.mart4 {
	margin-top: 4em;
}

/*~~ margin BOTTOM ~~*/

.marb1 {
	margin-bottom: 1em;
}

.marb2 {
	margin-bottom: 2em;
}

.marb3 {
	margin-bottom: 3em;
}

.marb4 {
	margin-bottom: 4em;
}

/*~~ GLOBAL ~~*/

.center {
  margin: 0 auto;
}

.upper {
  text-transform: uppercase;
}

.lower {
  text-transform: lowercase;
}

.hidden {
  display: none;
}

.noscroll {
  height:100%;
  overflow:hidden;
}

.figure {
  overflow:hidden;
  position:relative;
}

.figure img, .stretch {
  width:100%;
  height:auto;
}
