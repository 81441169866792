/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  GLOBALS
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.half, .third, .twothird, .fourth, .threefourth, .fifth {
  @include mq-sm {
    width: 100%;
  }
}

body, html {
}

body {
  margin: 0;
  padding: 0;
  font: 400 normal 16px/16px $font-main, sans-serif;
 @include font-smoothing;
  color: $color-primary;
  background-color: $color-white;
  @include mq-md {
    font-size: 15px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  line-height: 1em;
}

h1 {
  font-size: 2.063em;
  font-family: $font-headline;
}

h2 {
  font-size: 1.875em;
  font-weight: 600;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1.125em;
}

h6 {
  font-size: 1em;
}

ul {

}

li {

}

p {
  font-size: 1.125em;
  line-height: 1.5em;
}

p.small {
  font-size: 0.75em;
}

a:link, a:visited {
  text-decoration: none;
  color: $color-primary;
  @include transition(all 0.5s ease);
}

a:hover, a:active {
  color: $color-black;
}

strong {
  font-weight: 600;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  FONTS
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.font-main {
  font-family: $font-main;
}

.font-main-bold {
  font-family: $font-main-bold;
}

.font-main-light {
  font-family: $font-main-light;
}

.font-headline {
  font-family: $font-headline;
}

.font-headline-bold {
  font-family: $font-headline-bold;
}

.font-headline-light {
  font-family: $font-headline-light;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  BG COLOR
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.bgwhite {
  background-color: $color-white;
}

.bgprimary {
  background-color: $color-primary;
}

.bgsecondary {
  background-color: $color-secondary;
}

.bgtertiary {
  background-color: $color-tertiary;
}

.bgsurrogate {
  background-color: $color-surrogate;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  TEXT COLOR
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.twhite {
  color: $color-white !important;
}

.tprimary {
  color: $color-primary;
}

.tsecondary {
  color: $color-secondary;
}

.ttertiary {
  color: $color-tertiary;
}

.tsurrogate {
  color: $color-surrogate;
}

::selection {
    background-color: $color-surrogate;
    color: $color-white;
}

::-moz-selection {
   background-color: $color-surrogate;
    color: $color-white;
}

::-o-selection {
    background-color: $color-surrogate;
    color: $color-white;
}

::-ms-selection {
    background-color: $color-surrogate;
    color: $color-white;
}

::-webkit-selection {
    background-color: $color-surrogate;
    color: $color-white;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  STRUCTURE
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.browsehappy {
  margin: 0.2em 0;
  background: $color-white;
  color: $color-black;
  padding: 0.2em 0;
}

header, footer, section {
clear: both;
width: 100%;
position: relative;
}

.wrapper, .wrapper-full {
  max-width: 700px;
  width: 85%;
  margin: 0 auto;
  position: relative;
}

.wrapper-full {
 width: 100%;
}

.responsive-background-image {
 background-size: cover !important;
 background-position: top !important;
 img {
    display: none;
 }
}

.cover {
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  CAROUSEL
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

#landing-carousel {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  left: 0px;
  top: 0px;

  @include mq-sm {
    position: static;
    height: 450px;
    overflow: hidden;
  }

  #jh-logo {
    position: absolute;
    @include centerall();
    z-index: 1000;
    width: 70%;
    height: auto;
    max-width: 415px;

    @include mq-sm {
      max-width: 300px;
    }
  }

  @keyframes animate-home-arrow {
		0% {
			padding-bottom: 0;
		}
		100% {
			padding-bottom: .5em;
		}
	}

  #down-arrow {
		animation: animate-home-arrow 1s linear 0s infinite alternate;
		@include center-horz();
		z-index: 1001;
		bottom: 1.5em;
    @include svg-shadow(2px 2px 2px rgba(0,0,0,.5));

    img {
      width: 60px;
      height: auto;

      @include mq-sm {
        width: 40px;
      }

    }

		&:hover {
			animation-play-state: paused;
		}
  }
  
  .landing-carousel-shadow {
    width: 100%;
    height: 100%;
    background-image: url('../../images/jh-shadow.png');
    background-size: 100% 100%;
    background-position: 0px 0px;
    z-index: 999;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .landing-carousel-image {
    width: 100%;
    height: 100%;

    @include mq-md {
			height: 100%;
			background-position: center 0 !important;
		}

		div {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
      @include transition(transform 200s ease);
      width: 100%;
      height: 100%;
		}

		&.cycle-slide-active > div {
			z-index: 600;
			-moz-transform: scale(2, 2);
			-webkit-transform: scale(2, 2);
      transform: scale(2, 2);
      @include transition(transform 45s ease);
		}

  }

  #landing-carousel-a {
    background-image: url('../../images/jhleatherworks1.jpg');
  }

  #landing-carousel-b {
    background-image: url('../../images/jhleatherworks2.jpg');
  }

  #landing-carousel-c {
    background-image: url('../../images/jhleatherworks3.jpg');
  }

  #landing-carousel-d {
    background-image: url('../../images/jhleatherworks4.jpg');
  }

  #landing-carousel-e {
    background-image: url('../../images/jhleatherworks5.jpg');
  }

  #landing-carousel-f {
    background-image: url('../../images/jhleatherworks6.jpg');
  }

  #landing-carousel-g {
    background-image: url('../../images/jhleatherworks7.jpg');
  }

}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  CAROUSEL SPACER
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.header-spaceholder {
  width: 100%;
  height: 100vh;

  @include mq-sm {
    display: none;
  }
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  JAMIE INFO
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

#introduction {

  width: 100%;
  height: 100vh;
  background-image: url('../../images/jhleatherworks-background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @include mq-sm {
    height: auto;
    min-height: 100vh;
  }

  .wrapper {

    padding: 5em 0 5em 0;
    @include centerall();

    @include mq-sm {
      padding: 3em 0 3em 0;
      @include centernone();
    }


    h1 {
      color: $color-white;
      font-size: 2.063em;
      margin: 0 0 1.5em 0;
      text-transform: uppercase;
      line-height: 1.25em;

      @include mq-sm {
        font-size: 1.9em;
        margin-bottom: 1em;
      }

    }

    p {

      color: $color-white;
      font-size: 1.125em;
      line-height: 1.75em;
      margin: 0 0 4em 0;

      @include mq-sm {
        font-size: 1em;
      }

    }

    .intro-boot {

      img {
        width: 50%;
        height: auto;
        max-width: 300px;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 3em;
      }

      ul {
        margin: 0;
        padding: 0;
        display: block;
        margin: 0 0 3em 0;
        text-align: center;

        li {
          display: inline-block;
          color: $color-white;
          font-size: 1.125em;
          line-height: 1.75em;
          margin-left: 20px;
          margin-right: 20px;
          letter-spacing: 1px;

          @include mq-sm {
            display: block;
          }

          a {
            color: $color-white;
  
            &:hover {
              color: #e2cbae;
            }
  
          }

          &.intro-boot-name {
            font-family: $font-headline;
            font-size: 1.5rem;
            letter-spacing: normal;
          }



        }

      }

    }



  }

}
