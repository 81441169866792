/*******************************************************
 BASIC FORM SCSS
********************************************************/
.form-bg {
	padding: 3rem 0;
	margin: 3rem 0;
}

#interest-list {
	:invalid,
	:-moz-submit-invalid,
	:-moz-ui-invalid {
		box-shadow: none;
	}
	display: table;
	width: 100%;
	color: $color-surrogate;
	padding: 3rem 0;
	div {
		&.fielditem {
			position: relative;
			padding: 0.75em;
			&.fieldlist {
				padding-bottom: 0;
			}

			&.focused {
				label {
					transform: translateY(-125%);
					font-size: 0.75em;
					left: 5px;
				}
			}
		}
		// Moving Label
		&.fieldinput {
			label {
				color: $color-surrogate;
				position: absolute;
				left: 12px;
				top: 33px;
				font-size: 1em;
				padding: 0 0.75em;
				@include transition(all 200ms ease);
			}
			&.focused {
				label {
					transform: translateY(-125%);
					font-size: 0.8em;
					left: 3px;
					top: 20px;
				}
				input {
					/*border-bottom: 1px solid $color-primary;*/
				}
			}
		}
		&#sec1,
		&#sec2,
		&#sec3 {
			padding: 0;
		}
	}
	label {
		color: $color-primary;
		font-size: 1em;
		padding: 0.5em 0.75em;
		text-align: left;
		@include transition(all 200ms ease);
		&.full {
			width: 10%;
			display: block;
		}
	}
	label.comments {
		display: block;
		padding-left: 0;
	}
	input:-webkit-autofill {
		//   -webkit-box-shadow: inset 0 0 0px 9999px white;
	}
	input {
		&[type='text'],
		&[type='email'] {
			width: 100%;
			background: $color-secondary-light;
			color: $color-surrogate;
			padding: 0.75rem 0.5rem 0.75rem 0.5rem;
			letter-spacing: 0.5px;
			border-bottom: 0;
			font-size: 1em;
			margin-top: 0.25em;
			@include transition(all 500ms ease);
		}
	}
	select {
		@include transition(all 500ms ease);
	}
	select.required-highlight {
		background: #ff8b8b;
		border-bottom: none;
		padding-left: 10px;
	}
	.required-highlight label {
		color: #ff8b8b !important;
		margin-left: 10px;
	}
	.required-highlight input {
		background: #ff8b8b;
		border-bottom: none;
	}
	textarea {
		width: 100%;
		background: $color-secondary-light;
		color: $color-primary;
		border: 0;
		/*border-bottom: 1px solid lighten($color-primary, 60%);;*/
		padding: 0.75em 0.5em;
		margin-top: 0.5em;
		letter-spacing: 0.5px;
		font-family: $font-main;
		font-size: 1em;
		resize: none;
		height: 10em;
		overflow: auto;
		// @include font-smoothing();
		@include transition(0.2s all ease-in-out);
		&:focus {
			/*border: 1px solid $color-white;*/
		}
	}

	#success,
	#process {
		i.loading {
			display: block;
			animation: rotation 1s infinite linear;
			border: 2px solid fn-transparent-color($color-primary, 0.4);
			border-radius: 50%;
			border-top-color: $color-primary;
			height: 2em;
			width: 2em;
			text-align: center;
			margin: 0 auto;
			margin-bottom: 1rem;
		}
		@keyframes rotation {
			to {
				transform: rotate(360deg);
			}
		}
	}
}

/* CONTROL GROUP */

.control-group {
	.full {
		margin-bottom: 0.75em;
	}
	display: inline-block !important;
	vertical-align: top !important;
	text-align: left !important;
}

.control {
	display: block;
	position: relative;
	padding: 0 0 0 30px !important;
	margin-bottom: 0.9em !important;
	cursor: pointer;
	input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
}

.control__indicator {
	position: absolute;
	top: -3px;
	left: 0;
	height: 20px;
	width: 20px;
	background: #e6e6e6;
}

.control--radio .control__indicator {
	border-radius: 50%;
}

.control {
	&:hover input ~ .control__indicator {
		background: #ccc;
	}
	input {
		&:focus ~ .control__indicator {
			background: #ccc;
		}
		&:checked ~ .control__indicator {
			background: $color-primary;
		}
	}
	&:hover input:not([disabled]):checked ~ .control__indicator {
		background: $color-black;
	}
	input {
		&:checked:focus ~ .control__indicator {
			background: $color-primary;
		}
		&:disabled ~ .control__indicator {
			background: #e6e6e6;
			opacity: 0.6;
			pointer-events: none;
		}
	}
}

.control__indicator:after {
	content: '';
	position: absolute;
	display: none;
}

.control input:checked ~ .control__indicator:after {
	display: block;
}

.control--checkbox {
	.control__indicator:after {
		left: 8px;
		top: 4px;
		width: 5px;
		height: 10px;
		border: solid $color-primary;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
	}
	input:disabled ~ .control__indicator:after {
		border-color: #7b7b7b;
	}
}

.control--radio {
	.control__indicator:after {
		left: 7px;
		top: 7px;
		height: 6px;
		width: 6px;
		border-radius: 50%;
		background: $color-primary;
	}
	input:disabled ~ .control__indicator:after {
		background: #7b7b7b;
	}
}

.select {
	position: relative;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	select {
		display: inline-block;
		width: 100%;
		cursor: pointer;
		padding: 0.75em 12px 0.75em 12px;
		letter-spacing: 0.5px;
		margin-top: 0.25em;
		outline: 0;
		border: 0;
		border-radius: 0;
		border-bottom: 1px solid lighten($color-primary, 60%);
		background: lighten($color-primary, 60%);
		color: $color-primary;
		-webkit-appearance: none;
		font-family: $font-main;
		// @include appearance(none);
		// @include font-smoothing;
		font-size: 1em;
		box-sizing: content-box;
		&::-ms-expand {
			display: none;
		}
		//   &:hover,
		&:focus {
			color: $color-primary;
			border-bottom: 1px solid $color-primary;
		}
		&:disabled {
			opacity: 0.5;
			pointer-events: none;
		}
		option {
			//font-size: 1rem;
			color: $color-black;
			background: $color-primary;
		}
	}
}

.select__arrow {
	position: absolute;
	top: 22px;
	right: 15px;
	width: 0;
	height: 0;
	pointer-events: none;
	border-style: solid;
	border-width: 8px 5px 0 5px;
	border-color: $color-primary transparent transparent transparent;
}

.select select {
	&:hover ~ .select__arrow,
	&:focus ~ .select__arrow {
		border-top-color: $color-primary;
	}
	&:disabled ~ .select__arrow {
		border-top-color: $color-secondary;
	}
}

#interestlist {
  background-color: fn-transparent-color($color-primary, 0.85);
	padding-top: 4rem;
	// margin-bottom: 4rem;
	@include mq-md {
		// margin-bottom: 3rem;
		padding-top: 3rem;
	}
	@include mq-sm {
		// margin-bottom: 2rem;
		padding-top: 2rem;
	}
}
#interest-list {
	button[type='submit'] {
		border: none;
		margin: 0;
		width: 220px;
		height: 44px;
		z-index: 400;
		color: $color-black;
		position: relative;
		margin: 0 auto;
		font-size: 1.5em!important;
		padding: 0.5em;
		background-color: fn-transparent-color($color-primary, 0.7);
		@include transition(0.2s all ease-in-out);
		font-family: $font-headline;
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		border-radius: none;
		&:hover {
			background-color: $color-primary;
			cursor: pointer;
			color: $color-tertiary;
		}
	}
	div {
		&#process,
		&#success {
			padding: 12em 0;
			color: $color-primary;
		}
	}
	.select-box {
		label {
			display: none;
			transform: translateY(-125%);
			font-size: 0.75em;
			left: 12px;
			top: 22px;
			position: absolute;
			top: 28px;
		}

		&.selected {
			SELECT {
				border-bottom: 1px solid #4d4d4f;
			}
			label {
				display: block
			}
		}
	}
}

.required-text {
	position: absolute;
  right: 0.75rem;
  font-size: 0.85rem;
}

#form-submit {
	padding-top: 2em;
}

#submitbutton {
	display: block;
	text-transform: uppercase;
	font-size: 1.5rem;
	width: 100%;
	max-width: 200px;
	padding: 0.6em;
	margin: 1em auto 0;
	text-align: center;
  background-color: $color-white;
  border-radius: 15px;
  border: 4px solid $color-surrogate;
	color: $color-surrogate;
	@include transition(background-color 0.5s ease);

	&:hover {
		cursor: pointer;
    background-color: $color-surrogate;
    color: $color-white;
	}

	@include mq-md {
		font-size: 1.35em;
	}

}

#process h4,
#success h4 {
	margin: 0 0 0.75em 0;
	padding: 0;
	text-align: center;
	color: $color-primary;
	font-size: 1.5em;
	text-transform: uppercase;
	letter-spacing: 1px;
}

#process p,
#success p {
	color: $color-primary;
	text-align: center;
	margin: 0;
	padding: 0;
}

#errorchecking.alert {
	background: $color-white;
	text-align: center;
	clear: both;
	margin-bottom: 2em;
	padding: 1em;
	margin: 0.9em;
	border: 1px solid $color-tertiary;
	p {
		margin: 0;
		padding: 0;
		color: $color-tertiary;
		text-transform: uppercase;
		font-family: $font-main;
		font-weight: 600;
	}
}


/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: none;
	border-bottom: 1px solid $color-primary;
	-webkit-text-fill-color: $color-primary;
	-webkit-box-shadow: 0 0 0px 1000px lighten($color-primary, 60%) inset;
	transition: background-color 5000s ease-in-out 0s;
}